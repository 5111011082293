import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Dimmer,
  Loader,
  Segment,
} from 'semantic-ui-react';
import IconButton from "../../../components/shared/buttons/IconButton";
import { areFiguresIncomplete, areThereDuplicateFigures, getBudgetDetails, getBudgetDetailsTotals, isBudgetT2ReadOnly } from '../../../utils/projectPlanningUtils';
import { capitalize } from '../../../utils/stringUtils';

import moment from "moment";
import { toast } from 'react-semantic-toasts';
import { history } from "../../../routers/AppRouters";
import { startGetCutoffPM } from '../../../store/actions/core/administration/cutoff';
import { startGetBudgetsById, startUpdateBudget } from '../../../store/actions/core/project-planning/budget';
import { clearbudget, setFinancialDataModalOpen, startSaveBudgetT2 } from '../../../store/actions/shared/professionalFigures';
import constants from '../../../utils/constants';
import BudgetT2NextEditProductContainer from './BudgetT2NextEditProductContainer';
import BudgetT2NextEditServiceContainer from './BudgetT2NextEditServiceContainer';
import _ from 'lodash';


const FIELDS = {
  project_code: 'Project Code',
  currency_code: 'Currency',
  start_date: 'Start Date',
  end_date: 'End Date',
  status: 'Status',
  project_type: 'Project Type',
  offering_type: 'Offering Type',
};


function BudgetT2NextEditContainer(props) {
  const budget_id = props.match.params.budget_id

  useEffect(() => {
    props.getBudget(budget_id)
    props.getCutoffPM()

    return () => {
      props.clearBudget()
    }
  }, [])

  const onSave = (isConfirm) => {
    // console.log('onSave', );

    const onFailure = res => {
      var description = 'Error saving Budget';
      if (res.response !== undefined && res.response.status === 406) {
        description = res.response.data.error;
      }
      toast({
        title: 'Error',
        description: description,
        type: 'error',
        icon: 'file',
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: 'fly left',
      });
    };

    const onSuccess = res => {
      // console.log("onSuccess", res);

      toast({
        title: 'Success',
        description: 'The Budget has been saved',
        icon: 'check',
        type: 'success',
        time: constants.TOAST_SUCCESS_TIMEOUT,
        animation: 'fly left',
      });
      // history.push('/project-planning/budget_next')

      setTimeout(() => {
        history.push('/project-planning/budget_next');
      }, 1500); // Ritardo per evitare il reindirizzamento troppo veloce, il toast non viene visualizzato
    }

    let body = null;
    if (props.budget.type === "PROD") {
      body = {
        status: "confirm",
        budget_details: props.costsAndRevenues.map(x => ({
          ...x,
          total_revenues: null,
          billable_expenses: null,
          total_cost: null,
          expenses: null,
          delta_adj: null,
          delta_bill_exp: null,
        }))
      }

      const totalRevenues = props.costsAndRevenues.reduce((a, b) => a + b.revenues, 0)

      const totalCosts = props.costsAndRevenues.reduce((a, b) => a + b.cost, 0)
      if (props.actual_forecast_details.pfe_revenues !== totalRevenues) {
        toast({
          title: 'Warning',
          description: "Revenues cannot be different fom PFE revenues",
          type: 'warning',
          icon: 'file',
          time: constants.TOAST_WARNING_TIMEOUT,
          animation: 'fly left',
        });
        return;
      }
      if (props.actual_forecast_details.pfe_costs !== totalCosts) {
        toast({
          title: 'Warning',
          description: "Costs cannot be different fom PFE costs",
          type: 'warning',
          icon: 'file',
          time: constants.TOAST_WARNING_TIMEOUT,
          animation: 'fly left',
        });
        return;
      }

    } else {
      // BUG FIX Check Time Material - - - 
      // Check eseguito sulle figure per verificare le revenues non corretto. 
      // La modifica considera: 
      // - le revenues calcolate dalle figure per i mesi aperti (forecast);
      // - le revenues salvate a DB (historical) per i mesi chiusi. 

      let statusActualOrSaved = false;

      if (typeof props.budget.status !== 'undefined' && props.budget.status !== null) {
        statusActualOrSaved = (props.budget.status === 'actual' || props.budget.status === 'saved');
        console.log('statusActualOrSaved: ', statusActualOrSaved);
      }


      // Data ultimo SuperCutOff
      const superCutoffDate = props.cutoffs.find(x => x.is_super_cutoff) !== undefined
        ? new Date(props.cutoffs.find(x => x.is_super_cutoff).to_date)
        : new Date();
      // console.log("onSave ~ superCutoffDate:", superCutoffDate)

      // Calcolo per il mese attuale
      const actualDate = new Date(superCutoffDate.getFullYear(), superCutoffDate.getMonth() + 2, 0)
      // console.log("onSave ~ actualDate:", actualDate)

      const actualYear = actualDate.getFullYear();
      // console.log("onSave ~ actualYear:", actualYear)
      const actualMonth = actualDate.getMonth() + 1;
      // console.log("onSave ~ actualMonth:", actualMonth)


      // - - - Forecast and Historical - - - 
      // Dettagli futuri (forecast)
      const forecast = props.actual_forecast_details.details.filter(item => {
        return item.year > actualYear || (item.year === actualYear && item.month >= actualMonth);
      });
      // console.log("filteredArray ~ forecast:", forecast)


      // Dettagli storici (historical)
      const historical = props.actual_forecast_details.details.filter(item => {
        return item.year < actualYear || (item.year === actualYear && item.month <= actualMonth);
      });
      // console.log("filteredArray ~ historical:", historical)


      // Details
      const forecast_detail = getBudgetDetails(props.actualForecastProfessionalFigures, forecast, props.budget.project_type);
      // console.log("onSave ~ forecast_detail:", forecast_detail)

      const historical_detail = historical;
      // console.log("onSave ~ historical_detail:", historical_detail)


      // Check ultimo mese historical
      const lastMonthHistorical = historical_detail[historical_detail.length - 1];
      // console.log("onSave ~ lastMonthHistorical:", lastMonthHistorical)

      // Check ultimo mese forecast
      const lastMonthForecast = forecast_detail[0];
      // console.log("onSave ~ lastMonthForecast:", lastMonthForecast)

      // Dati combinati: historical + forecast
      let combined_details = [];
      // Nuovo oggetto per mese actual/save: dati storici + dati futuri

      // Fix statusActualOrSaved, dopo un update actual non venivano inviate le revenue dal DB "storicizzate", ma quelle calcolate.
      let actualMonthDetails = []

      if (statusActualOrSaved) {
        actualMonthDetails = lastMonthHistorical ? {
          ...lastMonthHistorical,
          revenues: (lastMonthHistorical && lastMonthHistorical.revenues) ? lastMonthHistorical.revenues : 0,
          delta_adj: (lastMonthHistorical && lastMonthHistorical.delta_adj) ? lastMonthHistorical.delta_adj : 0,
          billable_expenses: (lastMonthHistorical && lastMonthHistorical.billable_expenses) ? lastMonthHistorical.billable_expenses : 0,
          total_revenues: (lastMonthHistorical && lastMonthHistorical.total_revenues) ? lastMonthHistorical.total_revenues : 0,
        } : null;
      } else {
        actualMonthDetails = lastMonthHistorical ? {
          ...lastMonthHistorical,
          revenues: (lastMonthForecast && lastMonthForecast.revenues) ? lastMonthForecast.revenues : 0,
          delta_adj: (lastMonthForecast && lastMonthForecast.delta_adj) ? lastMonthForecast.delta_adj : 0,
          billable_expenses: (lastMonthForecast && lastMonthForecast.billable_expenses) ? lastMonthForecast.billable_expenses : 0,
          total_revenues: (lastMonthForecast && lastMonthForecast.total_revenues) ? lastMonthForecast.total_revenues : 0,
        } : null;
      }

      console.log("onSave ~ actualMonthDetails:", actualMonthDetails)

      // Fix AEP-5406: Verifica se l'ultimo mese storico è uguale al primo mese di previsione
      if (historical.length > 0 && forecast.length > 0) {
        const lastHistoricalMonth = historical[historical.length - 1];
        console.log("onSave ~ lastHistoricalMonth:", lastHistoricalMonth)
        const firstForecastMonth = forecast[0];
        console.log("onSave ~ firstForecastMonth:", firstForecastMonth)

        const isOverlapping = lastHistoricalMonth.year === firstForecastMonth.year &&
          lastHistoricalMonth.month === firstForecastMonth.month;
        console.log("onSave ~ isOverlapping:", isOverlapping)
        if (isOverlapping) {
          historical.pop();
          forecast.shift();
        }
      }
      
      if (Array.isArray(forecast_detail) && forecast_detail.length >= 0 &&
        Array.isArray(historical_detail) && historical_detail.length > 0) {
        
        // FIX AEP-5443. Correlato a 5406
        combined_details = _.uniqBy([
          ...historical,
          ...(actualMonthDetails ? [actualMonthDetails] : []),
          ...forecast
        ], item => `${item.year}-${item.month}`);

      } else if (Array.isArray(forecast_detail) && forecast_detail.length > 0) {
        combined_details = forecast_detail;
      } else if (Array.isArray(historical_detail) && historical_detail.length > 0) {
        combined_details = historical_detail;
      }
      // - - - Forecast and Historical - - - 


      const figures = props.actualForecastProfessionalFigures.map(figure => (
        figure.days.map(h => ({
          year: h.year,
          month: h.month,
          hours: h.day * 8,
          level: figure.level,
          area: figure.area,
          company_code: figure.company_code,
          internal: figure.external ? 0 : 1,
          sale_level: figure.sales_level,
          fromDB: undefined,
          cost_hour: figure.daily_cost / 8,
          currency_code: figure.currency_code,
          exchange_rate: figure.exchange_rate,
          site_percentage_expense: null,
          cost_hour_expense: null,
          //BUG FIX UAT 52: mandare daily_rate reale invece che 0, perche' 0 spacca lo storico del DB
          hourly_rate: figure.real_daily_rate / 8,
          real_daily_rate: undefined
        }))
      )).flat()
      body = {
        status: isConfirm ? 'confirm' : 'draft',
        budget_figures: figures,
        budget_details: combined_details // Dati combinati: historical + forecast
      }

      if (areFiguresIncomplete(props.actualForecastProfessionalFigures)) {
        return toast({
          title: 'Warning',
          description: 'There are incomplete professional figures, please delete or complete them.',
          type: 'warning',
          icon: 'file',
          time: constants.TOAST_WARNING_TIMEOUT,
          animation: 'fly left',
        });
      }
      if (areThereDuplicateFigures(props.actualForecastProfessionalFigures, "budget")) {
        return toast({
          title: 'Warning',
          description: 'There are duplicate professional figures, please remove them.',
          type: 'warning',
          icon: 'file',
          time: constants.TOAST_WARNING_TIMEOUT,
          animation: 'fly left',
        });
      }

      console.log("onSave ~ combined_details:", combined_details)

      
      let totals = getBudgetDetailsTotals(combined_details)
      console.log("onSave ~ totals:", totals)
      
      

      if (Number((totals.revenues + totals.deltaRevenues).toFixed(2)) !== Number(props.actual_forecast_details.pfe_revenues.toFixed(2))) {
        return toast({
          title: 'Warning',
          description: 'The revenues + delta revenues declared in the budget are different from the revenues of the PFE. Please review your revenue distribution.',
          type: 'warning',
          icon: 'file',
          time: constants.TOAST_WARNING_TIMEOUT,
          animation: 'fly left',
        });
      }

      if (Number((totals.billableExpenses + totals.deltaBillableExpenses).toFixed(2)) !== Number(props.actual_forecast_details.pfe_billable_expenses.toFixed(2))) {
        return toast({
          title: 'Warning',
          description: 'The billable expenses + delta billable expenses in the budget are different from the billable expenses of the PFE. Please review your billable expenses distribution.',
          type: 'warning',
          icon: 'file',
          time: constants.TOAST_WARNING_TIMEOUT,
          animation: 'fly left',
        });
      }
    }
    props.startSaveBudgetT2(budget_id, body, onFailure, onSuccess)
  }

  const handleUpdate = () => {
    const onFailure = (res) => {
      let description = ""
      if (res.response && res.response.status === 406) {
        description = res.response.data.error
      } else if (res.response && res.response.status === 500) {
        description = res.response.data.error // missing external rates
      }
      else {
        description = "The Budget hasn't been updated. Try again."
      }
      toast({
        title: "Error",
        description: description,
        type: "error",
        icon: "file",
        time: constants.TOAST_ERROR_TIMEOUT,
        animation: "fly left",
      })
    }

    const onSuccess = (res) => {
      //console.log("onSuccess", res);
      toast({
        title: "Success",
        description: "The Budget has been updated.",
        icon: "check",
        type: "success",
        time: constants.TOAST_SUCCESS_TIMEOUT,
        animation: "fly left",
      })

      setTimeout(() => {
        window.location.reload();
      }, 1500); // Ritardo per evitare il reindirizzamento troppo veloce
    }

    var current_date = moment().format("YYYY-MM-DD")
    props.updateBudget(budget_id, current_date, onSuccess, onFailure)
  }

  //FIX bug 82 T0 in status "Confirm" o "Change Requested" non deve avere i tasti "Save" o "Confirm" cliccabili. 
  const isReadOnly = isBudgetT2ReadOnly(props.budget.status)

  return (
    <div style={{ display: 'flex', flexDirection: 'row', height: 'calc(100vh - 50px)' }}>
      <Dimmer active={props.loading} inverted>
        <Loader indeterminate inverted content="Loading" size="medium" />
      </Dimmer>

      {props.budget !== undefined &&
        <>
          <Segment style={{ height: 'calc(100vh - 50px)', maxWidth: '200px', display: 'flex', flexDirection: 'column', margin: "0" }}>
            {Object.keys(FIELDS).map(key => (
              <div className="custom-form-field" key={key}>
                <label>{FIELDS[key]}</label>
                <div>{key === "status" ? capitalize(props.budget[key]) : props.budget[key]}</div>
              </div>
            ))}
            <div style={{ marginTop: 'auto', display: 'flex', flexDirection: 'column' }}>
              {props.budget.type === "TS" && <IconButton label='Costs and Revenues' icon='chart bar' color='green' onClick={() => props.setFinancialDataModalOpen(true)} />}
              {props.budget.type === "TS" && <IconButton label='Save' icon='hdd outline' color='blue' onClick={() => onSave(false)} disabled={areFiguresIncomplete(props.actualForecastProfessionalFigures) || isReadOnly} />}
              {props.budget.type === "PROD" && <IconButton label='Confirm Budget' icon='check circle outline' color='teal' onClick={() => onSave(true)} disabled={areFiguresIncomplete(props.actualForecastProfessionalFigures) || isReadOnly} />}
              {props.budget.type === "TS" && <IconButton label='Update Actual' icon='check circle outline' color='teal' onClick={handleUpdate} disabled={isReadOnly} />}
            </div>
          </Segment>

          {props.budget.type === "PROD" && <BudgetT2NextEditProductContainer budget_id={budget_id} />}
          {props.budget.type === "TS" && <BudgetT2NextEditServiceContainer budget_id={budget_id} />}
        </>
      }
    </div>
  )
}


const mapStateToProps = state => ({
  loading: state.professionalFiguresReducer.loading_budget ||
    state.cutoffReducer.loading_pm,

  budget: state.professionalFiguresReducer.budget.length === 0 ? {} : state.professionalFiguresReducer.budget.find(b => b.status_type === "t2"),

  actual_forecast_details: state.professionalFiguresReducer.actual_forecast_details,
  costsAndRevenues: state.professionalFiguresReducer.costsAndRevenues,
  actualForecastProfessionalFigures: state.professionalFiguresReducer.actualForecastProfessionalFigures,

  cutoffs: state.cutoffReducer.cutoff_pm === undefined ? [] : state.cutoffReducer.cutoff_pm,
});

const mapDispatchToProps = dispatch => {
  return {
    startSaveBudgetT2: (budgetId, body, onFailure, onSuccess) => dispatch(startSaveBudgetT2(budgetId, body, onFailure, onSuccess)),
    getBudget: (id) => dispatch(startGetBudgetsById(id)),
    getCutoffPM: () => dispatch(startGetCutoffPM()),
    setFinancialDataModalOpen: (isOpen) => dispatch(setFinancialDataModalOpen(isOpen)),
    updateBudget: (id, current_date, onSuccess, onFailure) => dispatch(startUpdateBudget(id, current_date, onSuccess, onFailure)),
    clearBudget: () => dispatch(clearbudget())
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BudgetT2NextEditContainer);