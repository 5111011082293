import * as React from "react";
import { Button, Container, Header, Tab } from "semantic-ui-react";
import { toast } from "react-semantic-toasts";
import ManageTypesFormModal from "../../../../components/core/administration/manage-types/ManageTypesFormModal";
import TypesTable from "../../../../components/core/administration/TypesTable";
import { startGetUnits } from "../../../../store/actions/shared/unit";
import { connect } from "react-redux";
import {
  startDeleteExpenseType,
  startDeleteHourType,
  startEditExpenseType,
  startEditHourType,
  startGetExpenseTypes,
  startGetHourTypes,
  startInsertExpenseType,
  startInsertHourType
} from "../../../../store/actions/core/administration/manageTypes";
import _ from "lodash";
import { capitalize } from "../../../../utils/stringUtils";
import constants from "../../../../utils/constants";
import Can from "../../../../abilities/Can";


const hourCols = [
  { id: "code", name: "Code" },
  { id: "name", name: "Description" },
  { id: "active", name: "Active", type: "boolean" }
];

const expenseCols = [
  { id: "name", name: "Description" },
  { id: "code", name: "Code" },
  { id: "active", name: "Active", type: "boolean" }
];

export const types = {
  HOUR: "hour",
  EXPENSE: "expense"
};

class ManageTypesContainer extends React.Component {
  state = {
    editable: false,
    type: types.HOUR,
    activeIndex: 0,
    showFormModal: false,
    values: {},
    editCode: true
  };

  componentWillMount() {
    this.props.getExpenseTypesList();
    this.props.getHourTypesList();
    this.props.getUnitsData();
  }


  onSelectRow = selectedRow => {
    //console.log(this.state.type, selectedRow);
    switch (this.state.type) {
      case "expense":
        //console.log("edit expense ", selectedRow);
        if (selectedRow.unit !== "")
          selectedRow.unit_check = 1;
        else
          selectedRow.unit_check = 0;
        this.setState(prevState => ({
          ...prevState,
          editable: false,
          type: "expense",
          values: selectedRow,
          showFormModal: true
        }));
        break;
      // case default should work like hour case because initial state the tab is returned as undefined
      case "hour":
      default:
        //console.log("edit hour ", selectedRow);
        this.setState(prevState => ({
          ...prevState,
          editable: false,
          type: "hour",
          values: selectedRow,
          showFormModal: true
        }));
        break;
    }
  };

  handleEdit = () => {
    this.setState(prevState => ({
      ...prevState,
      editable: true,
      editCode: false
    })
    );
  };



  handleSubmit = () => {
    const onFailure = res => {
      //console.log("onError", res);
      if (JSON.stringify(res).includes("409")) {
        toast(
          {
            title: "Error",
            description: "The " + capitalize(this.state.type) + " Type code *" + this.state.values.code + "* already exists. Type a new one.",
            type: "error",
            icon: "file",
            time: constants.TOAST_ERROR_TIMEOUT,
            animation: 'fly left'
          });
      } else {
        toast(
          {
            title: "Error",
            description: capitalize(this.state.type) + " type table hasn't been updated. Try again.",
            type: "error",
            icon: "file",
            time: constants.TOAST_ERROR_TIMEOUT,
            animation: 'fly left'
          });
        this.closeModal();
      }

    };

    const onSuccess = res => {
      //console.log("onSuccess", res);
      toast(
        {

          title: "Success",
          description: capitalize(this.state.type) + " type table updated",
          icon: "check",
          type: "success",
          time: constants.TOAST_SUCCESS_TIMEOUT,
          animation: 'fly left'
        }
      );
      this.closeModal();
    };

    const { values, type } = this.state;
    if (type === types.EXPENSE) {
      if (!this.state.values.hasOwnProperty("conversion_rate"))
        values.conversion_rate = 1.0;
      if (!this.state.values.hasOwnProperty("unit"))
        values.unit = null;
      if (!this.state.values.hasOwnProperty("allowance"))
        values.allowance = false;
      if (!this.state.values.hasOwnProperty("need_receipt"))
        values.need_receipt = false;
      if (!this.state.values.hasOwnProperty("auto_approve"))
        values.auto_approve = false;
    }

    if (!this.state.values.hasOwnProperty("default_type"))
      values.default_type = false;

    if (type === types.HOUR && !values.id) {
      //console.log("here insert new hour type with ", values);
      this.props.onInsertHourType(values, onSuccess, onFailure);
    } else if (type === types.EXPENSE && !values.id) {
      //console.log("here insert new expense type with ", values);
      this.props.onInsertExpenseType(values, onSuccess, onFailure);
    } else if (type === types.HOUR && values.id !== "") {
      //console.log("here edit hour type with ", values);
      this.props.onEditHourType(values.id, values, onSuccess, onFailure);
    } else if (type === types.EXPENSE && values.id !== "") {
      //console.log("here edit expense type with ", values);
      this.props.onEditExpenseType(values.id, values, onSuccess, onFailure);
    }

  };

  handleDelete = () => {
    const onFailure = res => {
      //console.log("onError", res);
      toast(
        {
          title: "Error",
          description: capitalize(this.state.type) + " type hasn't been deactivated",
          type: "error",
          icon: "file",
          time: constants.TOAST_ERROR_TIMEOUT,
          animation: 'fly left'
        });
      this.closeModal();
    };

    const onSuccess = res => {
      //console.log("onSuccess", res);
      toast(
        {

          title: "Success",
          description: capitalize(this.state.type) + " type deactivated",
          icon: "check",
          type: "success",
          time: constants.TOAST_SUCCESS_TIMEOUT,
          animation: 'fly left'
        }
      );
      this.closeModal();
    };

    //console.log("I'm deleting ", this.state.values.id);
    if (this.state.type === types.HOUR) {
      this.props.onDeleteHourType(this.state.values.id, onSuccess, onFailure);
    } else if (this.state.type === types.EXPENSE) {
      this.props.onDeleteExpenseType(this.state.values.id, onSuccess, onFailure);
    }

  };

  handleChange = (e, data) => {
    //console.log(data, data.checked, data.value);
    if (data.value === "" && data.checked === undefined) {
      data.value = undefined;
    } else if (data.checked !== undefined) data.value = data.checked;

    if (data.value < 0) data.value = 0;

    let values = {
      ...this.state.values,
      [data.name]: data.value
    };

    if (data.name === "unit_check" && !data.checked && values.unit) {
      //console.log("dentro");
      delete (values.unit);
    }

    if (data.name === "need_receipt" && !data.checked) {
      values["double_receipt"] = false
    }


    this.setState({
      values: values
    });

    //console.log(values);
  };

  handleTabChange = (e, { activeIndex }) => {
    let activeTab = "hour";
    switch (activeIndex) {
      case 0:
        activeTab = "hour";
        break;
      case 1:
        activeTab = "expense";
        break;
      default:
        break;
    }
    this.setState(prevState => ({
      ...prevState,
      type: activeTab,
      activeIndex: activeIndex
    }));
  };


  closeModal = () => {
    this.setState(prevState => ({
      ...prevState,
      showFormModal: false,
      values: {},
      editable: false,
      editCode: true
    }));
  };

  render() {

    return (
      <Container>

        <Header>
          {"Manage Types"}
        </Header>
        <Can I='manage_types:Read' a='all'>
          {this.state.showFormModal &&
            <ManageTypesFormModal
              type={this.state.type}
              editable={this.state.editable}
              values={this.state.values}
              handleChange={this.handleChange}
              onClose={this.closeModal}
              onSubmit={this.handleSubmit}
              onEdit={this.handleEdit}
              onDelete={this.handleDelete}
              loadings={this.props.loadings}
              options={this.props.options}
              editCode={this.state.editCode}
              active={this.state.values.hasOwnProperty("active") ? this.state.values.active : true}
            />
          }
          {
            <Tab
              onTabChange={this.handleTabChange}
              defaultActiveIndex={this.state.activeIndex}
              panes={[
                {
                  menuItem: "Hour Types",
                  render: () => (
                    <Tab.Pane loading={this.props.loadingHour}>
                      <Can I='manage_types:Create' a='all'>
                        <Button
                          floated="right"
                          onClick={() =>
                            this.setState({ type: "hour", showFormModal: true, editable: true, editCode: true })
                          }
                        >
                          New Hour Type
                        </Button>
                      </Can>
                      <TypesTable
                        typeName="hour"
                        loading={this.props.loadingHour}
                        types={this.props.hours}
                        columns={hourCols}
                        onSelectRow={this.onSelectRow}
                        readOnlyData={this.state.editable}
                      />
                    </Tab.Pane>
                  )
                },
                {
                  menuItem: "Expense Types",
                  render: () => (
                    <Tab.Pane clearing>
                      <Can I='manage_types:Create' a='all'>
                        <Button
                          floated="right"
                          onClick={() =>
                            this.setState({
                              type: "expense",
                              showFormModal: true,
                              editable: true,
                              editCode: true
                            })
                          }
                        >
                          New Expense Type
                        </Button>
                      </Can>

                      <TypesTable
                        typeName="expense"
                        loading={this.props.loadingExpense}
                        types={this.props.expenses}
                        columns={expenseCols}
                        onSelectRow={this.onSelectRow}
                        readOnlyData={this.state.editable}
                      />
                    </Tab.Pane>
                  )
                }
              ]}
            />
          }
        </Can>
      </Container >
    );
  }
}

const mapStateToProps = state => ({
  loadingHour: state.manageTypesReducer.loadingHour,
  loadingExpense: state.manageTypesReducer.loadingExpense,
  hours: state.manageTypesReducer.hours,
  expenses: state.manageTypesReducer.expenses,
  options: {
    unit: _.toArray(
      _.mapValues(state.unitReducer.units, o => {
        return {
          key: o.id,
          value: o.code,
          text: o.code
        };
      })
    )
  },
  loadings: {
    unit: state.unitReducer.loading
  }
});

const mapDispatchToProps = dispatch => {
  return {
    onInsertHourType: (hourType, onSuccess, onFailure) => dispatch(startInsertHourType(hourType, onSuccess, onFailure)),
    onEditHourType: (id, hourType, onSuccess, onFailure) => dispatch(startEditHourType(id, hourType, onSuccess, onFailure)),
    onDeleteHourType: (id, onSuccess, onFailure) => dispatch(startDeleteHourType(id, onSuccess, onFailure)),
    onInsertExpenseType: (expenseType, onSuccess, onFailure) => dispatch(startInsertExpenseType(expenseType, onSuccess, onFailure)),
    onEditExpenseType: (id, expenseType, onSuccess, onFailure) => dispatch(startEditExpenseType(id, expenseType, onSuccess, onFailure)),
    onDeleteExpenseType: (id, onSuccess, onFailure) => dispatch(startDeleteExpenseType(id, onSuccess, onFailure)),
    getHourTypesList: () => dispatch(startGetHourTypes()),
    getExpenseTypesList: () => dispatch(startGetExpenseTypes()),
    getUnitsData: () => dispatch(startGetUnits())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageTypesContainer);
